const cursorPaths = {
    normal: '/cursors/cursor_circle.svg',
    fallback: '/cursors/cursor_circle.cur',
};

const theme = {
    fonts: {
        heroNewBold: {
            fontFamily:
                '"hero-new", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu,Cantarell, "Helvetica Neue", sans-serif',
            fontWeight: '800',
        },
        heroNewMedium: {
            fontFamily:
                '"hero-new", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu,Cantarell, "Helvetica Neue", sans-serif',
            fontWeight: '400',
        },
    },
    cursors: {
        circle: {
            cursor: `url(${cursorPaths.normal}) 16 16, url(${cursorPaths.normal}), url(${cursorPaths.fallback}), pointer`,
        },
    },
    colors: {
        black: '#000',
        white: '#FFF',
        blue: '#0000FF',
        cyan: '#00FFFF',
        fuchsia: '#FF00FF',
        lightGrey: '#939393',
        darkGrey: '#7A7A7A',
        bgGrey: '#F4F4F4',
        dividerGrey: '#DFDFDF',
        borderGrey: 'rgba(255,255,255,0.35)',
        darkBlue: '#000714',
        starsNavigationBg: '#000016',
        transparent: 'transparent',
        red: '#fa3232',
        green: '#29d657',
        modalOverlay: 'rgba(0,0,255,0.3)',
        form: {
            bgGrey: '#fdfdfd',
            borderGrey: '#e3e3e3',
        },
    },
    dimensions: {
        containerMaxWidth: '90em',
        headerHeight: '4.65rem',
        headerTabletHeight: '4.6875rem',
    },
    transition: 'cubic-bezier(0.4, 0.0, 0.2, 1) 0.3s',
} as const;

export type SiteTheme = typeof theme;

declare module '@ackee/fela' {
    interface Theme extends SiteTheme {}
}

export default theme;
